import images from "./images";
import {FaPaperPlane, FaEdit, FaRocket, FaShoppingCart, FaFileAlt, FaPhoneAlt, FaEnvelopeOpen, FaMapMarkerAlt} from "react-icons/fa";
import {BiDollarCircle} from "react-icons/bi";
import {ImMagicWand} from "react-icons/im";
import {AiOutlineReload} from "react-icons/ai";

const gradient = "url(#blue-gradient)" ;

const services = [
    {
        id: 1,
        icon: <FaPaperPlane style = {{ fill: gradient }} />,
        title: "Clearing forest boundaries",
        text: "Clearing forest boundaries involves physically demarcating the edges of the forest area to define its limits. This often includes clearing vegetation, marking lines or paths, and ensuring the boundaries are well-defined to prevent encroachment and unauthorized entry.\n" +
            "\n"
    },
    {
        id: 2,
        icon: <BiDollarCircle style = {{ fill: gradient }} />,
        title: "Putting up signs",
        text: "Installing signs involves placing informational or regulatory markers at key points within the forest. These signs typically provide directions, warnings, rules, and other important information to visitors, helping to guide behavior, protect wildlife, and prevent damage to the environment."
    },
    {
        id: 3,
        icon: <FaRocket style = {{ fill: gradient }} />,
        title: "Making and installing beacons",
        text: "Making and installing beacons refers to creating and placing visible markers or signals throughout the forest. These beacons serve various purposes such as navigation aids, boundary markers, or communication points for forest guards and visitors alike."
    },
    {
        id: 4, 
        icon: <FaEdit style = {{ fill: gradient }} />,
        title: "Removing intruders from the forests",
        text: "Removing intruders refers to the action of evicting individuals or groups who unlawfully enter the forest for activities like logging, mining, or hunting. This enforcement is crucial for maintaining the integrity of the forest ecosystem and protecting biodiversity."
    },
    {
        id: 5,
        icon: <ImMagicWand style = {{ fill: gradient }} />,
        title: "Collaborating with villagers in forest protection and hiring forest guards",
        text: "This involves engaging local communities (villagers) in efforts to protect the forest. It includes hiring and training forest guards who patrol the area to prevent illegal activities like logging or poaching. "
    },
    {
        id: 6,
        icon: <FaShoppingCart style = {{ fill: gradient }} />,
        title: "providing villagers with protective equipment such as uniforms, motorcycles, and boots",
        text: "Providing them with necessary equipment such as uniforms, motorcycles for mobility, and boots enhances their effectiveness and safety during patrols."
    }
];

const about = [
    {
        id: 7,
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris finibus leo et diam fermentum ullamcorper. Nulla venenatis nibh sollicitudin tincidunt gravida. Nam convallis justo et ligula luctus suscipit. Etiam eu nisi turpis. Donec sollicitudin accumsan quam, rhoncus sagittis metus semper quis. Praesent convallis mauris sed ipsum lobortis facilisis. Nulla cursus sem non nunc sagittis, a volutpat mauris lobortis. Nulla ut feugiat tellus. Nam dictum nisi nec scelerisque auctor"
    }
]

const qualities = [
    {
        id: 8,
        icon: <FaFileAlt style = {{ fill: gradient }} />,
        title: "Ideas & Plans",
        text: "Developing forest conservation for sustainable use and benefiting current and future generations."
    },
    {
        id: 9,
        icon: <AiOutlineReload style = {{ fill: gradient }}  />,
        title: "Prompt Strategies",
        text: "Ensuring biodiversity protection through enhanced private forest management. And also to improve beekeeping activities"
    },

];

const features = [
    {
        id: 10,
        title: "Forest Conservation Education",
        text: "Providing forest conservation education to the community. i.e Educating the community on the importance and methods of conserving forests."
    },
    {
        id: 11,
        title: "Protecting Forests",
        text: "Protecting the forest from charcoal burners, loggers, and illegal farming. i.e Safeguarding forests from activities that lead to deforestation and environmental degradation."
    },
    {
        id: 12,
        title: "Ecotourism Activities",
        text: "Establishing ecotourism activities along with providing tourism education. i.e Promoting ecotourism to support conservation efforts and educate visitors on the environment."
    },
    {
        id: 13,
        title: "Beekeeping Initiatives",
        text: "Initiating beekeeping activities, including establishing a bee farm and training on beekeeping. i.e Supporting sustainable livelihoods through beekeeping and enhancing skills in apiculture."
    }, 
    {
        id: 14,
        title: "Tree Planting",
        text: "Planting indigenous and exotic trees to replace those cut in the forests. i.e Restoring forest cover by replanting trees to maintain biodiversity and ecological balance."
    },
    {
        id: 15,
        title: "Removing Forest Invaders & Regular Patrols",
        text: "Removing forest invaders and their settlements to restore the forest, also Conducting regular patrols to control fires, illegal hunting, charcoal burning, and logging. i.e Evicting illegal settlers and ensuring the forest can regenerate naturally. also Maintaining frequent surveillance to prevent and mitigate activities that harm the forest ecosystem."
    }
];

const portfolio = [
    {
        id: 16,
        title: "Community Workshops",
        text: "Organize workshops and seminars to educate locals on the importance of forest conservation.",
        image: images.portfolio_img_1,
    },
    {
        id: 17,
        title: "Educational Materials",
        text: "Develop and distribute brochures, pamphlets, and digital content about sustainable forest management practices.",
        image: images.portfolio_img_2,
    },
    {
        id: 18,
        title: "School Programs",
        text: "Integrate forest conservation topics into school curriculums and organize field trips to forests for practical learning experiences.",
        image: images.portfolio_img_3,
    }
];

const testimonials = [
    {
        id: 19,
        name: "Marie Jordan., Community Leader",
        text: "The forest conservation education program has truly transformed our village. We now understand the importance of preserving our forests, and we've seen a significant reduction in illegal logging. The community is more united in our efforts to protect our natural resources.",
        image: images.customer_img_1,
        rating: 3
    },
    {
        id: 20,
        name: "Ann Brown., Beekeeper",
        text: "Thanks to the beekeeping project, I have a new source of income that supports my family. The training provided was excellent, and now I’m producing and selling honey. It’s rewarding to contribute to forest conservation while also improving our livelihood.",
        image: images.customer_img_2,
        rating: 5
    },
    {
        id: 21,
        name: "Andrew Bill., Volunteer",
        text: "The school programs have been incredible. My students are more engaged and passionate about the environment. The field trips to the forest have provided them with hands-on learning experiences that textbooks can’t match. It’s inspiring to see the next generation so invested in conservation.",
        image: images.customer_img_3,
        rating: 2
    },
    {
        id: 22,
        name: "Jason Stawer., Teacher",
        text: "The school programs have been incredible. My students are more engaged and passionate about the environment. The field trips to the forest have provided them with hands-on learning experiences that textbooks can’t match. It’s inspiring to see the next generation so invested in conservation.",
        image: images.customer_img_4,
        rating: 4
    },
    {
        id: 23,
        name: "Lisa Green., NGO Partner",
        text: "Working with the conservation team has been a highly rewarding experience. Their dedication and collaborative approach have made a real difference. We've successfully integrated our resources and expertise to achieve common goals, and the results speak for themselves.",
        image: images.customer_img_5,
        rating: 2
    },
    {
        id: 24,
        name: "Anna Doe., Village Elder",
        text: "The integrated approach to conservation and community development has strengthened our village. Through various programs, we have not only improved our environment but also boosted our economic stability and social cohesion. It’s a holistic solution that addresses our needs on multiple levels.",
        image: images.customer_img_6,
        rating: 4
    }
]

const contact = [
    {
        id: 25,
        icon: <FaPhoneAlt style = {{ fill: gradient }} />,
        info: "+255 673 599 704",
        text: "Contact us through provided mobile phone above, We are available only from monday to saturday \n 08:00 AM to 16:00 PM"
    },
    {
        id: 26,
        icon: <FaEnvelopeOpen style = {{ fill: gradient }} />,
        info: "P.O Box 106206, Dar es salaam",
        text: "We assure our availability will only be from monday to saturday 08:00 AM to 16:00 PM"
    },
    {
        id: 27,
        icon: <FaMapMarkerAlt style = {{ fill: gradient }} />,
        info: "Dar es salaam Tanzania, Boko-Bunju",
        text: "Our office is located at Boko Bunju near Bagamoyo Road"
    }
]

const sections = {services, about, qualities, features, portfolio, testimonials, contact};

export default sections;