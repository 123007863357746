import React from 'react';
import sections from '../../constants/data';
import images from '../../constants/images';
import "./Qualities.css";

const Qualities = () => {
  return (
    <section className='qualities section-p bg-md-black' id = "qualities">
        <div className='container'>
            <div className='qualities-content grid'>
                <div className='content-left'>
                    <div className='section-t text-center'>
                        <h3>We are Professionals</h3>
                        <p className='text'>This company also aims to be an example that forests can be utilized in other ways besides harvesting, while still generating profit through beekeeping and tourism.

                            The company currently has six forests located in the districts of Bagamoyo, Chalinze, and Handeni in the Pwani and Tanga regions. These forests are:

                            Kinase 1, located in Wami Mkoko village, Msata ward, Bagamoyo district, covering an area of 57.07 hectares with map registration JB 2851.
                            The second forest is in Mwetemo village, Kiwangwa ward, covering an area of 202.5 hectares with map registration JB no 2852.
                            The third forest is in Mkenge village, Fukayosi ward, Bagamoyo district, covering an area of 77.1 hectares.</p>
                    </div>

                    <div className='item-list grid text-white'>
                        {
                            sections.qualities.map(quality => {
                                return (
                                    <div className='item flex' key = {quality.id}>
                                        <div className='item-icon flex flex-center'>
                                            {quality.icon}
                                        </div>
                                        <div className='item-text'>
                                            <h3 className='item-title fs-25'>Ideas & Plans</h3>
                                            <p className='text'>{quality.text}</p>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>

                <div className='content-right' >
                    <img src = {images.professionals_main_img} alt = "" />
                </div>
            </div>
        </div>
    </section>
  )
}

export default Qualities
