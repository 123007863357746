import header_main_img from "../assets/images/header_main_img.jpg";
import about_main_img from "../assets/images/about_main_img.jpg";
import form_main_img from "../assets/images/form_main_img.jpg";
import professionals_main_img from "../assets/images/professionals_main_img.jpg";
import video_cover_img from "../assets/images/video_cover_img.jpg";
import customer_img_1 from "../assets/images/customer_img_1.jpg";
import customer_img_2 from "../assets/images/customer_img_2.jpg";
import customer_img_3 from "../assets/images/customer_img_3.jpg";
import customer_img_4 from "../assets/images/customer_img_4.jpg";
import customer_img_5 from "../assets/images/customer_img_5.jpg";
import customer_img_6 from "../assets/images/customer_img_6.jpg";

import portfolio_img_1 from "../assets/images/portfolio_img_1.jpg";
import portfolio_img_2 from "../assets/images/portfolio_img_3.jpg";
import portfolio_img_3 from "../assets/images/portfolio_img_2.jpg";

const images = {header_main_img, about_main_img, form_main_img, professionals_main_img, video_cover_img, customer_img_1, customer_img_2, customer_img_3, customer_img_4, customer_img_5, customer_img_6, portfolio_img_1, portfolio_img_2, portfolio_img_3};

export default images;