import React from 'react';
import "./Contact.css";

const Map = () => {
  return (
    <div className='map-content'>
        {/* eslint-disable-next-line */}
        <iframe
            src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d7318.932522567604!2d39.151206599691086!3d-6.633216312233095!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sboko%20bunju!5e0!3m2!1sen!2stz!4v1718440578905!5m2!1sen!2stz"
            width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"></iframe>
        {/*<iframe*/}
        {/*    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2026780.3664863622!2d37.533194136347525!3d-7.177278691329506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x185c917ea61f884d%3A0xa9c7c1288c9dd36b!2sPwani%20Region!5e0!3m2!1sen!2stz!4v1718322943887!5m2!1sen!2stz"*/}
        {/*    width="100%" height="450" style={{border:0}} allowFullScreen="" loading="lazy"*/}
        {/*    referrerPolicy="no-referrer-when-downgrade"></iframe>*/}
      {/*<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2430.067702021103!2d-1.899753284141808!3d52.47790987980627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4870bc896c750ee5%3A0x9da3f5ae36c39f33!2sNew%20Street%20Station%2C%20East%20Mews%2C%20Birmingham%20B2%204QA%2C%20UK!5e0!3m2!1sen!2snp!4v1660992980861!5m2!1sen!2snp" width="100%" height="450" style={{border:0}}></iframe>*/}
    </div>
  )
}

export default Map
