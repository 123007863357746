import React from 'react';
import Navbar from '../Navbar/Navbar';
import { Link as ScrollLink } from 'react-scroll';
import "./Header.css";
import {FaPaperPlane} from "react-icons/fa";

const Header = () => {
  return (
      <header className='header flex flex-center flex-column'>
          <Navbar />
          <div className='container'>
              <div className='header-content text-center flex flex-column'>
                  <h1 className='text-uppercase header-title'>Who are we?</h1>
                  <p className='text-lead'>We are forest conservators and those engaged in mining. We conserve and manage private natural forests.</p>
                  <ScrollLink
                      to="about"
                      spy={true}
                      smooth={true}
                      offset={-70}
                      duration={500}
                      className='btn header-btn btn-blue'
                  >
                      <FaPaperPlane /> <span>get started</span>
                  </ScrollLink>
              </div>
          </div>
      </header>
  )
}

export default Header