import React from 'react';
import "./About.css";
import images from '../../constants/images';

const About = () => {
  return (
    <section className='about section-p bg-dark' id = "about">
        <div className='container'>
            <div className='about-content grid text-center'>
                <div className = "content-left">
                    <img src = {images.about_main_img} alt = "" />
                </div>
                <div className='content-right'>
                    <div className='section-t'>
                        <h3>About Us</h3>
                    </div>
                    <p className = "text">Kinase Mamas Forest is a company registered with the Registrar of Companies under number 92403. The company engages in mining and the conservation of private natural forests based on the Forest Policy of 1998 and the Forest Act No. 14 of 2002.

                        This company also aims to be an example that forests can be utilized in other ways besides harvesting, while still generating profit through beekeeping and tourism.

                        The company currently has six forests located in the districts of Bagamoyo, Chalinze, and Handeni in the Pwani and Tanga regions.</p>
                    {/*<p className='text'>Lorem ipsum dolor sit, </p>*/}
                </div>
            </div>
        </div>
    </section>
  )
}

export default About