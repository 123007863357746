// export default Navbar
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import "./Navbar.css";
import {FaTwitter, FaFacebook, FaInstagram} from "react-icons/fa";
import { IoMdRocket } from "react-icons/io";

const Navbar = () => {
    const [navToggle, setNavToggle] = useState(false);

    const navHandler = () => {
        setNavToggle(prevData => !prevData);
    };

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    return (
        <nav className='navbar w-100 flex'>
            <div className='container w-100'>
                <div className='navbar-content flex fw-7'>
                    <div className='brand-and-toggler flex flex-between w-100'>
                        <Link to="/" className='navbar-brand fs-26' onClick={scrollToTop}>KinaseMamasForest</Link>
                        <div
                            type="button"
                            className={`hamburger-menu ${navToggle ? 'hamburger-menu-change' : ""}`}
                            onClick={navHandler}
                        >
                            <div className='bar-top'></div>
                            <div className='bar-middle'></div>
                            <div className='bar-bottom'></div>
                        </div>
                    </div>

                    <div className={`navbar-collapse ${navToggle ? 'show-navbar-collapse' : ""}`}>
                        <div className='navbar-collapse-content'>
                            <ul className='navbar-nav'>
                                <li className='text-white'>
                                    <ScrollLink
                                        activeClass="active"
                                        to="about"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        onClick={navHandler}
                                    >
                                        About
                                    </ScrollLink>
                                </li>
                                <li className='text-white'>
                                    <ScrollLink
                                        activeClass="active"
                                        to="features"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        onClick={navHandler}
                                    >
                                        Features
                                    </ScrollLink>
                                </li>
                                <li className='text-white'>
                                    <ScrollLink
                                        activeClass="active"
                                        to="testimonials"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        onClick={navHandler}
                                    >
                                        Customers
                                    </ScrollLink>
                                </li>
                                <li className='text-white'>
                                    <ScrollLink
                                        activeClass="active"
                                        to="contact"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}
                                        onClick={navHandler}
                                    >
                                        Contacts
                                    </ScrollLink>
                                </li>
                            </ul>
                            <ul className='navbar-social flex'>
                                <li className='text-white'>
                                    <a href="https://twitter.com/your-twitter-handle" className='flex flex-center'><FaTwitter /></a>
                                </li>
                                <li className='text-white'>
                                    <a href="https://instagram.com" className='flex flex-center'><FaInstagram /></a>
                                </li>
                                <li className='text-white'>
                                    <a href="https://facebook.com" className='flex flex-center'><FaFacebook /></a>
                                </li>
                            </ul>
                            <div className='navbar-btns'>
                                <ScrollLink
                                    to="about"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    onClick={navHandler}
                                >
                                    <button type="button" className='btn'><IoMdRocket /> <span>Get started</span></button>
                                </ScrollLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    );
};

export default Navbar;
