// sendEmailService.js

import axios from 'axios';

const SENDGRID_API_KEY = 'YOUR_SENDGRID_API_KEY'; // Replace with your SendGrid API Key
const SENDGRID_API_URL = 'https://api.sendgrid.com/v3/mail/send';

const sendEmail = async (emailData) => {
    try {
        const response = await axios.post(SENDGRID_API_URL, {
            personalizations: [{
                to: [{ email: 'twaloadam@gmail.com' }], // Replace with recipient's email
                subject: 'Customer Contact Form Submission From Your Website',
            }],
            from: { email: emailData.email }, // Replace with your verified sender email in SendGrid
            content: [{
                type: 'text/plain',
                value: `
                    Name: ${emailData.name}
                    Email: ${emailData.email}
                    Address: ${emailData.address}
                    Body: ${emailData.body}
                `,
            }],
        }, {
            headers: {
                Authorization: `Bearer ${SENDGRID_API_KEY}`,
                'Content-Type': 'application/json',
            },
        });

        console.log('Email sent:', response.data);
        return response.data;
    } catch (error) {
        console.error('Error sending email:', error.response.data);
        throw new Error('Failed to send email');
    }
};

export default sendEmail;
