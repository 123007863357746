import React from 'react';
import { Formik } from 'formik';
import sendEmail from './sendEmailService'; // Import your SendGrid service
import images from '../../constants/images';
import Info from './Info';
import Map from './Map';

const Contact = () => {

    const handleSubmit = async (values, { setSubmitting }) => {
        try {
            await sendEmail(values);
            alert('Email sent successfully!');
        } catch (error) {
            alert('Error sending email. Please try again.');
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className='contact section-p-top bg-black' id="contact">
            <div className='container'>
                <div className='contact-content grid text-center'>
                    <div className='contact-left'>
                        <div className='section-t'>
                            <h3>Let's Talk?</h3>
                            <p className='text'>We appreciate your Opinions, As our valued customer, we are just welcoming you contacting us for any need?</p>
                        </div>

                        <Formik
                            initialValues={{ name: "", email: '', address: '', body: '' }}
                            validate={values => {
                                const errors = {};

                                if (!values.name) {
                                    errors.name = "Name is required";
                                } else if (!/^[A-Za-z\s]*$/.test(values.name)) {
                                    errors.name = "Invalid name format";
                                }

                                if (!values.email) {
                                    errors.email = 'Email is required';
                                } else if (
                                    !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
                                ) {
                                    errors.email = 'Invalid email address';
                                }

                                if (!values.address) {
                                    errors.address = "Address is required";
                                }

                                if (!values.body) {
                                    errors.body = "Message is required";
                                }
                                return errors;
                            }}
                            onSubmit={(values, { setSubmitting }) => {
                                handleSubmit(values, { setSubmitting });
                            }}
                        >
                            {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                  isSubmitting,
                                  /* and other goodies */
                              }) => (
                                <form onSubmit={handleSubmit}>
                                    <div className='form-elem'>
                                        <input type="text" placeholder={'full name'} name="name" onChange={handleChange} onBlur={handleBlur} value={values.name} className="form-control" />
                                        <span className='form-control-text'>{errors.name && touched.name && errors.name}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <input type="email" placeholder={'email'} name="email" onChange={handleChange} onBlur={handleBlur} value={values.email} className="form-control" />
                                        <span className='form-control-text'>{errors.email && touched.email && errors.email}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <input type="text" placeholder={'address'} name="address" onChange={handleChange} onBlur={handleBlur} value={values.address} className="form-control" />
                                        <span className='form-control-text'>{errors.address && touched.address && errors.address}</span>
                                    </div>

                                    <div className='form-elem'>
                                        <textarea placeholder={'message'} name="body" onChange={handleChange} onBlur={handleBlur} value={values.body} className="form-control" />
                                        <span className='form-control-text'>{errors.body && touched.body && errors.body}</span>
                                    </div>

                                    <div className='flex flex-start'>
                                        <button type="submit" disabled={isSubmitting} className="submit-btn">contact us</button>
                                    </div>
                                </form>
                            )}
                        </Formik>
                    </div>

                    <div className='contact-right'>
                        <img src={images.form_main_img} alt="" />
                    </div>
                </div>
            </div>

            <Map />
            <Info />
        </section>
    )
}

export default Contact;
